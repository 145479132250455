<template>
  <div>



    <div class="wrapper">
      <section class="section-info section-info-sm" id="service-card">
        <div class="jcard-main jcard-dark">
          <div class="jtitle">
            <h3 class="jtitle-icon"><i class="fad fa-tools"></i></h3>
            <h3>{{ service.name }}</h3>
          </div>
          <p style="white-space: pre-wrap" class="p-text"><small>{{ service.text }}</small></p>
          <div class="jcard-info-item">
            <p class="p1-platform p-grey">Price</p>
            <h5>EUR {{ service.price }}</h5>
          </div>

          <div class="text-center">
            <div @click="openMakeAppointment" class="jbtn jbtn-long jbtn-white">
              MAKE AN APPOINTMENT <i class="fal fa-chevron-right"></i>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import C from "@/const";
import firebase from "@/firebase/firebaseInit";
const db = firebase.db;

export default {
  name: "ServiceCard",
  data() {
    return {
      serviceId: null,
      isLoading: false
    };
  },
  methods: {
    getService: function(serviceId) {
      //load service
      db.collection(C.COLLECTION.SERVICE_CATALOG)
        .doc(serviceId)
        .get()
        .then(doc => {
          //add id to doc
          const docdata = doc.data();
          docdata.id = doc.id;
          //set service doc in store
          this.$store.commit("setService", docdata);
        });
    },
    openMakeAppointment() {
      //   this.$store.commit("setService", this.servicesList[index]);
      this.$router.push({
        name: "Appointment",
        params: { id: this.watchId, service: this.serviceId }
        // params: { id: this.servicesList[index].id },
      });
    },
    formatServiceDescription(text){
      var result=text.replace(/,/g, "\n");
      return result;
    }
  },
  computed: {
    service: {
      get() {
        return this.$store.getters.getService;
      }
    }
  },
  mounted() {
    // TODO: have errorpage if ID in params is faulty

    //get watch id from params
    if (this.$route.params.watchId != null && this.$route.params.watchId != "") {
      this.watchId = this.$route.params.watchId;
    }
    //get service id from params
    if (this.$route.params.serviceId != null && this.$route.params.serviceId != "") {
      this.serviceId = this.$route.params.serviceId;
    }

    // check if sercice needs to be loaded
    if (!this.service || this.service.id != this.serviceId) {
      this.getService(this.serviceId);
    }
  }
};
</script>

<style scoped></style>
